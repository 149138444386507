import { useRequestAnimationFrame } from "@lindar-joy/goosicorn-quickies-library";
import { useEffect, useState } from "react";
import { styled } from "styled-components";
import anime from "animejs";
import { createEvent } from "react-event-hook";
import Mask from "../../assets/BKG/strip-mask.png";
import ContainerImage from "../../assets/UI/roulette-container.png";
import BearSymbol from "../../assets/UI/red-bear.png";
import WolfSymbol from "../../assets/UI/black-wolf.png";
import BuffaloSymbol from "../../assets/UI/green-buffalo.png";
import TargetSprite from "../../assets/UI/target.png";

const animationProperties = {
    position: 0,
    velocity: 0,
    targetSubStripPosition: 0,
    distanceToCover: 0,
    targetSubStripIndex: 0,
    deaccelerationDistance: 0
};

const SUBSTRIP_WIDTH = 2500;
let internalStop = false;

const StyledContainer = styled.div`
    background-image: url(${ContainerImage});
    background-size: cover;
    background-position: center;
    width: 646px;
    height: 118px;
    position: absolute;
    @media (max-width: 680px) {
        width: 100%;
    }

    @media (min-width: 681px) {
        mask-image: url(${Mask});
        mask-position: center;
        mask-size: cover;
    }
`;

const Strip = styled.div`
    height: 100px;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-left: ${(SUBSTRIP_WIDTH * 4) - (SUBSTRIP_WIDTH / 2) - 50}px;
`;

const RouletteStripNumberOrder = [1, 20, 14, 17, 9, 22, 18, 7, 12, 15, 19, 4, 21, 2, 25, 6, 13, 3, 11, 23, 10, 5, 24, 16, 8];

const StripNumbersContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
`;

const StripNumberContainer = styled.div<{ $symbol: string }>`
    width: 100px;
    height: 100px;
    background-image: url(${props => props.$symbol});
    background-size: contain;
    padding: 0px;
    flex-shrink: 0;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 44px;
    font-weight: bold;
`;

const TargetGraphic = styled.div`
    background-image: url(${TargetSprite});
    width: 140px;
    height: 140px;
    background-size: contain;
    margin-top: -20px;

`;

const DECELERATION = 0.0003;

const RouletteStrip: React.FC<RouletteStripProps> = ({
    onStarted,
    onRestitute,
    phase,
    target,
    ...props
}) => {

    let [position, setPosition] = useState<number>(0);

    useRequestAnimationFrame((deltaTime) => {
        if (internalStop) {
            if (target !== undefined) {
                
                const positionAtEndOfSubStrip = animationProperties.targetSubStripIndex * SUBSTRIP_WIDTH;
                const distanceToCover = (positionAtEndOfSubStrip + animationProperties.targetSubStripPosition) - animationProperties.position;
                animationProperties.distanceToCover = distanceToCover;
                if (distanceToCover < animationProperties.deaccelerationDistance) {
                    animationProperties.velocity = Math.max(animationProperties.velocity - (DECELERATION * deltaTime), 0.05);
                    if (distanceToCover < 5) {
                        onRestitute();
                    }
                }
                
            }
        }
        const strip = document.getElementById("roulette-strip");
        animationProperties.position += animationProperties.velocity * deltaTime;
        // @ts-ignore
        strip.style.transform = `translateX(-${animationProperties.position}px)`;
    });

    useEffect(() => {
        switch (phase) {
            case "STARTING":
                internalStop = false;
                animationProperties.velocity = 0;
                
                const strip = document.getElementById("roulette-strip");
                // @ts-ignore
                strip.style.transform = `translateX(-${animationProperties.position}px)`;
                anime({
                    targets: animationProperties,
                    velocity: 1,
                    time: 700,
                    complete: onStarted
                });
                break;
            case "STOPPING":
                if (target) {
                    internalStop = true;
                    const currentSubStripIndex = Math.floor(animationProperties.position / SUBSTRIP_WIDTH);
                    console.log("CSSI", currentSubStripIndex);
                    animationProperties.targetSubStripIndex = currentSubStripIndex + 2;
                    console.log("TSSI", animationProperties.targetSubStripIndex);
                    animationProperties.targetSubStripPosition = (SUBSTRIP_WIDTH / RouletteStripNumberOrder.length) * (RouletteStripNumberOrder.indexOf(target));
                    console.log("TSSP", animationProperties.targetSubStripPosition);
                    const deaccelerationDistance = animationProperties.velocity / (2 * DECELERATION);
                    console.log("DD", deaccelerationDistance);
                    animationProperties.deaccelerationDistance = deaccelerationDistance;
                }

                break;
            case "STOPPED":
                emitRouletteStop();
                internalStop = false;
                animationProperties.position = SUBSTRIP_WIDTH + animationProperties.targetSubStripPosition;
                animationProperties.velocity = 0;

                setPosition(SUBSTRIP_WIDTH + animationProperties.targetSubStripPosition);


                break;
        }
    }, [phase]);

    return (
        <div>
            <StyledContainer>
                <Strip id="roulette-strip">
                    <StripNumbersContainer>
                        {RouletteStripNumberOrder.map(rouletteStripNumber => (
                            <StripNumberContainer $symbol={symbolForNumber(rouletteStripNumber)}></StripNumberContainer>
                        ))}
                    </StripNumbersContainer>
                    <StripNumbersContainer>
                        {RouletteStripNumberOrder.map(rouletteStripNumber => (
                            <StripNumberContainer $symbol={symbolForNumber(rouletteStripNumber)}></StripNumberContainer>
                        ))}
                    </StripNumbersContainer>
                    <StripNumbersContainer>
                        {RouletteStripNumberOrder.map(rouletteStripNumber => (
                            <StripNumberContainer $symbol={symbolForNumber(rouletteStripNumber)}></StripNumberContainer>
                        ))}
                    </StripNumbersContainer>
                    <StripNumbersContainer>
                        {RouletteStripNumberOrder.map(rouletteStripNumber => (
                            <StripNumberContainer $symbol={symbolForNumber(rouletteStripNumber)}></StripNumberContainer>
                        ))}
                    </StripNumbersContainer>
                    <StripNumbersContainer>
                        {RouletteStripNumberOrder.map(rouletteStripNumber => (
                            <StripNumberContainer $symbol={symbolForNumber(rouletteStripNumber)}></StripNumberContainer>
                        ))}
                    </StripNumbersContainer>
                    <StripNumbersContainer>
                        {RouletteStripNumberOrder.map(rouletteStripNumber => (
                            <StripNumberContainer $symbol={symbolForNumber(rouletteStripNumber)}></StripNumberContainer>
                        ))}
                    </StripNumbersContainer>
                    <StripNumbersContainer>
                        {RouletteStripNumberOrder.map(rouletteStripNumber => (
                            <StripNumberContainer $symbol={symbolForNumber(rouletteStripNumber)}></StripNumberContainer>
                        ))}
                    </StripNumbersContainer>
                    <StripNumbersContainer>
                        {RouletteStripNumberOrder.map(rouletteStripNumber => (
                            <StripNumberContainer $symbol={symbolForNumber(rouletteStripNumber)}></StripNumberContainer>
                        ))}
                    </StripNumbersContainer>
                    <StripNumbersContainer>
                        {RouletteStripNumberOrder.map(rouletteStripNumber => (
                            <StripNumberContainer $symbol={symbolForNumber(rouletteStripNumber)}></StripNumberContainer>
                        ))}
                    </StripNumbersContainer>
                </Strip>
                
            </StyledContainer>
            <div style={{zIndex: 10, display: "flex", justifyContent: "center", width: "100%", position: "absolute"}}><TargetGraphic /></div>
        </div>
    )
};

export const { useRouletteStopListener, emitRouletteStop } = createEvent("roulette:stop")();

interface RouletteStripProps {
    target?: number;
    phase: Phase;
    onStarted: () => void;
    onRestitute: () => void;
}

export type Phase = "STARTING" | "SPINNING" | "STOPPING" | "STOPPED";

export const symbolForNumber = (number: number) => {
    switch (number) {
        case 1:
        case 14:
        case 9:
        case 18:
        case 12:
        case 3:
        case 19:
        case 21:
        case 25:
        case 23:
        case 5:
        case 16:
            return BearSymbol;
        
        case 20:
        case 22:
        case 7:
        case 15:
        case 4:
        case 2:
        case 17:
        case 6:
        case 11:
        case 8:
        case 10:
        case 24:
            return WolfSymbol;
        case 13:
            return BuffaloSymbol;
    }
    return "UNDEFINED";
}
export default RouletteStrip;
