import BetListImage from "./assets/splash/bet-list.png";
import BetSelection from "./assets/splash/bet-selection.png";
import BetSelector from "./assets/splash/bet-selector.png";
import RouletteStripImage from "./assets/splash/roulette-strip.png";

export const GameRules = (playerName: string) =>
<div>

    <h3>Buffalo Bills Roulette</h3>
    <p>RTP: 96%</p>
    
    <p>During the "place bets" phase, increase your bet amount by using the "+" bet amount button, or decrease your bet using the "-" bet amount button</p>
    <img src={BetSelector} style={{maxWidth: "100%"}}/>
    <p>During the "place bets" phase, place a bet by tapping on the red, green, or black buttons above the bet selector. Above the bet buttons is an image of the character associated with the colour: red for the Bear character, green for the Buffalo, and black for the Wolf.</p>
    <img src={BetSelection} style={{maxWidth: "100%"}}/>
    <p>Bets that you place will appear in the bets list, alongside the bets of other players in the game. Multiple bets may be placed on across multiple colours.</p>
    <img src={BetListImage} style={{maxWidth: "100%"}}/>
    <p>Once the place bets phase expires, the "roulette spin" phase will begin. If the roulette strip stops on a colour you have have selected, you will win a prize.</p>
    <img src={RouletteStripImage} style={{maxWidth: "100%"}}/>

    <p>The prizes are as follows:</p>
    <ul>
        <li>Winning bet on black or red: 2x stake</li>
        <li>Winning bet on green: 24x stake</li>
    </ul>
    
    <h3>Game rules</h3>
    <ul>
        <li>If the game is not currently in the Place Bets phase, wait for the next round to begin</li>
        <li>During the place bets phase, bets can be placed until the timer runs out</li>
        <li>If the roulette strip lands on a colour you have selected, you will win either 2x for black and red, or 24x for green</li>
        <li>In the event of interruption, the results of any bet placed by the player can be viewed in the game history</li>
    </ul>
</div>;
