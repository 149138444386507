import { styled } from "styled-components";
import { GetCurrencySymbol, ContextualAction, emitUiOpenMenu, emitAudioMute } from "@lindar-joy/goosicorn-quickies-library";
import { useState } from "react";

const StyledContainer = styled.div`
    z-index: 0;
    background-color: #401e14;
    background-size: contain;
    background-repeat: no-repeat;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 646px;
    padding-top: 10px;
    box-sizing: border-box;
    align-self: center;

    @media (max-width: 680px) {
        aspect-ratio: 4.3;
        margin: 0;
        width: 100%;
    }
`;
const BetSelector = styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    height: 90%;
    max-height: 50px;
    width: 100%;
`;

const BalanceContainer = styled.div`
    display: flex;
    width: 33%;
    justify-content: center;
    gap: 8px;
    height: 20px;
`;

const WinContainer = styled.div`
    display: flex;
    width: 33%;
    justify-content: right;
    gap: 8px;
    height: 20px;
`;

const TotalBetContainer = styled.div`
    display: flex;
    width: 33%;
    justify-content: left;
    gap: 8px;
    height: 20px;
`;

const BalanceLabel = styled.div`
    font-size: 12px;
    text-align: center;
    color: #BBBBBB;
    letter-spacing: 0.5pt;
`;

const BalanceValue = styled.div`
    font-variant-numeric: tabular-nums;
    text-align: center;
    letter-spacing: 0.5pt;
    font-size: 12px;
    font-weight: bold;
`;

const TopRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(100% - 20px);
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
`;

const BottomRowContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: calc(100% - 20px);
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
`;

const BetChangeButton = styled.div<{$disabled: boolean}>`
    height: 100%;
    aspect-ratio: 0.9;
    border-radius: 5px;
    background-color: ${ props => props.$disabled ? "#725e5e": "#604136"};
    pointer-events: ${ props => props.$disabled ? "none": "all"};
    box-shadow: 2px 2px 8px #261915;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
`;

const BetAmountButton = styled.div`
    height: 100%;
    aspect-ratio: 2.8;
    border-radius: 5px;
    background-color: #604136;
    box-shadow: 2px 2px 8px #261915;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
`;

const BetAmountLabel = styled.div`
    font-size: 10px;
    text-align: center;
    color: #BBBBBB;
    letter-spacing: 0.5pt;
`;

const BetAmountValue = styled.div`
    font-size: 13px;
    font-variant-numeric: tabular-nums;
`;

const MenuButton = styled.div`
    
`;

const SoundButton = styled.div`
    
`;


const HUD: React.FC<HUDProps> = ({
    balance,
    betAmount,
    betChangeDisabled,
    totalBet,
    totalWin,
    onBetDecrease,
    onBetIncrease,
    ...props
}) => {

    const [ muted, setMuted ] = useState<boolean>(false);

    return (
        <StyledContainer>
            
            <TopRowContainer>
                <MenuButton><ContextualAction disabled={false} icon="menu" onClick={() => {emitUiOpenMenu()}} size="md"></ContextualAction></MenuButton>
                <BetSelector>
                    <BetChangeButton $disabled={betChangeDisabled} onClick={ () => onBetDecrease() }>−</BetChangeButton>
                    <BetAmountButton><BetAmountLabel>Bet amount</BetAmountLabel><BetAmountValue>{GetCurrencySymbol() + ((betAmount / 100).toFixed(2))}</BetAmountValue></BetAmountButton>
                    <BetChangeButton $disabled={betChangeDisabled} onClick={ () => onBetIncrease() }>+</BetChangeButton>
                </BetSelector>    
                <SoundButton><ContextualAction disabled={false} icon={muted ? "sound_ off" : "sound_ On"} onClick={() => {
                    emitAudioMute({muted: !muted, setCacheState: true});
                    setMuted(!muted);
                }} size="md"></ContextualAction></SoundButton>
            </TopRowContainer>
            <BottomRowContainer>
                <TotalBetContainer>
                    <BalanceLabel>Total bet</BalanceLabel>
                    <BalanceValue>{GetCurrencySymbol() + ((totalBet / 100).toFixed(2))}</BalanceValue>
                </TotalBetContainer>
                <BalanceContainer>
                    <BalanceLabel>Balance</BalanceLabel>
                    <BalanceValue>{GetCurrencySymbol() + ((balance / 100).toFixed(2))}</BalanceValue>
                </BalanceContainer>
                <WinContainer>
                    <BalanceLabel>Win</BalanceLabel>
                    <BalanceValue>{GetCurrencySymbol() + ((totalWin / 100).toFixed(2))}</BalanceValue>
                </WinContainer>
            </BottomRowContainer>
        </StyledContainer>
    )
};

interface HUDProps {
    balance: number;
    totalBet: number;
    betAmount: number;
    totalWin: number;
    onBetIncrease: () => void;
    onBetDecrease: () => void;
    betChangeDisabled: boolean;
}

export default HUD;