import { GameHistoryRow } from "@lindar-joy/goosicorn-quickies-library";

export const GameHistoryRenderer = (gameRound: any) => {
    console.log(gameRound);
    return <div>
        <GameHistoryRow style={{display: "none"}}></GameHistoryRow>
        <GameHistoryRow><div>Player Selection</div><div>{ gameRound.selection }</div></GameHistoryRow>
        <GameHistoryRow><div>Game Result</div><div>{ gameRound.engineResult.colorResult }</div></GameHistoryRow>
        </div>
}
