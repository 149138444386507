import { styled } from "styled-components";

import ButtonRedPressed from "../../assets/UI/button-red-pressed.png";
import ButtonRedDefault from "../../assets/UI/button-red-default.png";
import ButtonRedHover from "../../assets/UI/button-red-hover.png";

import ButtonBlackPressed from "../../assets/UI/button-black-pressed.png";
import ButtonBlackDefault from "../../assets/UI/button-black-default.png";
import ButtonBlackHover from "../../assets/UI/button-black-hover.png";

import ButtonGreenPressed from "../../assets/UI/button-green-pressed.png";
import ButtonGreenDefault from "../../assets/UI/button-green-default.png";
import ButtonGreenHover from "../../assets/UI/button-green-hover.png";
import { CurrencyFormat } from "@lindar-joy/goosicorn-quickies-library";

const PressedButtonMap: Map<string, string> = new Map([
    ["RED", ButtonRedPressed],
    ["BLACK", ButtonBlackPressed],
    ["GREEN", ButtonGreenPressed]
]);

const HoverButtonMap: Map<string, string> = new Map([
    ["RED", ButtonRedHover],
    ["BLACK", ButtonBlackHover],
    ["GREEN", ButtonGreenHover]
]);

const DefaultButtonMap: Map<string, string> = new Map([
    ["RED", ButtonRedDefault],
    ["BLACK", ButtonBlackDefault],
    ["GREEN", ButtonGreenDefault]
]);

const StyledContainer = styled.div<{$buttonColour: string, $disabled: boolean}>`
    background-image: url(${props => DefaultButtonMap.get(props.$buttonColour)});
    width: 80%;
    aspect-ratio: 2.38;
    opacity: ${ props => props.$disabled ? 0.5 : 1};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(2px);
    font-variant-numeric: tabular-nums;
    font-weight: bold;
    font-size: 13px;
    pointer-events: ${props => props.$disabled ? "none" : "all"};
    cursor: pointer;
    
    &:active {
        background-image: url(${props => PressedButtonMap.get(props.$buttonColour)});
    }

    &:hover {
        background-image: url(${props => HoverButtonMap.get(props.$buttonColour)});
    }
`;

const BetButton: React.FC<BetButtonProps> = ({
    onSelect,
    buttonColour,
    bet,
    disabled,
    ...props
}) => {

    return (
        <StyledContainer $buttonColour={buttonColour} $disabled={disabled} onClick={() => onSelect()}>
            Bet {CurrencyFormat(bet)}
        </StyledContainer>
    )
};

interface BetButtonProps {
    bet: number;
    buttonColour: ButtonColour;
    disabled: boolean;
    onSelect: () => void;
}

export type ButtonColour = "RED" | "BLACK" | "GREEN";

export default BetButton;