import styled from 'styled-components';
import Background from "./assets/BKG/background.png";
import LogoImage from "./assets/UI/logo-bbr.png";


export const Logo = styled.div`
    background-image: url(${LogoImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 40px;

    @media (max-width: 680px) {
        height: 30px;
    }
`;

export const StyledMultiplierFrame = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const StyledCurrentWin = styled.span`
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
`;
export const AddMines = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const Plus = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;
export const NextMultiplierFrame = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const NextMultiplierText = styled.span`
    color: var(--white, #FFF);
    text-wrap: nowrap;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;

    @media (max-width: 680px) {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.2px;
    }
`;
export const NextMultiplier = styled.span`
    display: flex;
    padding: 1px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: var(--neutral-15, rgba(255, 255, 255, 0.15));
`;
export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    height: 100%;
`;
export const StyledMultiplierBar = styled.div`
    border-radius: 8px;
    background: var(--neutral-15, rgba(255, 255, 255, 0.15));
    display: flex;
    padding: 8px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    height: 40px;
    box-sizing: border-box;
`;

export const BackgroundContainer = styled.div`
    background-image: url(${Background});
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-position: center bottom;
    background-size: cover;
    

    @media (max-width: 680px) {
        transform: translateY(-14px) scale(1.5);
    }
`;

export const StyledGameContainer = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
`;

export const ControlsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
`;

export const ColumnContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: -50px;
`;