import { styled } from "styled-components";
import { IResult } from "../../events/showEvent";

import BlackShieldImage from "../../assets/UI/black-wolf-previous.png";
import RedShieldImage from "../../assets/UI/red-bear-previous.png";
import GreenShieldImage from "../../assets/UI/green-buffalo-previous.png";

const StyledContainer = styled.div`
    
    display: flex;
    flex-direction: row;
    width: 646px;
    box-sizing: border-box;
    background-color: #22100a;
    border-radius: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    height: 30px;
    align-items: center;
    gap: 8px;
    align-self: center;
    overflow: hidden;

    @media (max-width: 680px) {
        width: 93%;
    }
`;

const PreviousResult = styled.div<{$symbol: string}>`
    background-image: url(${props => props.$symbol});
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PreviousResultsText = styled.div`
    font-size: 10px;
    font-weight: bold;
    margin-left: 10px;
    display: flex;
    align-items: center;
    margin-right: 12px;

    @media (max-width: 680px) {
        font-size: 9px;
        margin-right: 6px;
    }
`;

const PreviousResults: React.FC<BetButtonsProps> = ({
    previousResults,
    ...props
}) => {
    
    return (
        <StyledContainer>
            <PreviousResultsText>PREVIOUS RESULTS</PreviousResultsText>
            {
                previousResults.slice(0, window.innerWidth <= 680 ? 9 : 19).map((previousResult, index) => (
                    <PreviousResult key={ index } $symbol={shieldForNumber(previousResult.result)} />
                ))
            }
        </StyledContainer>
    )
};

interface BetButtonsProps {
    previousResults: IResult[]
}

const shieldForNumber = (number: number) => {
    switch (number) {
        case 1:
        case 14:
        case 9:
        case 18:
        case 12:
        case 3:
        case 19:
        case 21:
        case 25:
        case 7:
        case 23:
        case 5:
        case 16:
            return RedShieldImage;
        case 20:
        case 22:
        case 0:
        case 15:
        case 4:
        case 2:
        case 17:
        case 6:
        case 11:
        case 8:
        case 10:
        case 24:
            return BlackShieldImage;
        case 13:
            return GreenShieldImage;
    }
    return "UNDEFINED";
}

export default PreviousResults;