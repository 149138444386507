import { styled } from "styled-components";
import { INamedBet } from "../../App";
import { GetCurrencySymbol, Icon } from "@lindar-joy/goosicorn-quickies-library";
import { RouletteColour } from "../BetButtons/BetButtons";
import BetsListBackgroundMobile from "../../assets/UI/bet-history-container-mobile.png";
import BetsListBackgroundDesktop from "../../assets/UI/bet-history-container-desktop.png";

import BlackShieldImage from "../../assets/UI/black-wolf-previous.png";
import RedShieldImage from "../../assets/UI/red-bear-previous.png";
import GreenShieldImage from "../../assets/UI/green-buffalo-previous.png";

const StyledContainer = styled.div<{$compact: boolean}>`
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 10px;
    background-image: url(${BetsListBackgroundDesktop});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 626px;
    box-sizing: border-box;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    align-self: center;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 70px;
    font-size: 11px;
    align-self: end;
    max-height: ${ props => props.$compact ? 150 : 300}px;
    justify-content: flex-start;
    flex-grow: 1;

    @media (max-width: 680px) {
        width: calc(100% - 30px);
        background-image: url(${BetsListBackgroundMobile});
    }
`;

const BetLine = styled.div`
    width: 97%;
    margin-right: 5px;
    display: flex;
    gap: 8px;
    font-size: 10px;
    padding-right: 20px;
    justify-content: space-between;
    font-variant-numeric: tabular-nums;
    align-items: center;
    margin-bottom: 2px;
`;

const BetTable = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
`;

const PlayerBetContainer = styled.div`
    background-color: #4f370a;
    border-radius: 10px;
    padding: 5px;
`;

const PlayerName = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 8px;
    width: 50%;
    text-overflow: "ellipsis";
    overflow: "hidden";
`;

const PlayerIcon = styled(Icon)`
    height: 15px;
`;

const PlayerBet = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    width: 50%;
`;

const BetListHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.5px;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    color: #DDDDDD;
`;

const BetsList: React.FC<BetButtonsProps> = ({
    bets,
    playerName,
    winningColour,
    winPhase,
    compact,
    ...props
}) => {
    
    const playerBets = {};
    const otherPlayerBets = {};

    let totalBets = 0;

    bets.forEach( bet => {
        if (bet.username === playerName) {
            if (!playerBets[bet.username + ":" + bet.selection]) {
                playerBets[bet.username + ":" + bet.selection] = {
                    username: bet.username,
                    bet: bet.bet,
                    selection: bet.selection
                }
            } else {
                playerBets[bet.username + ":" + bet.selection].bet += bet.bet;
            }
        
        } else {
            if (!otherPlayerBets[bet.username + ":" + bet.selection]) {
                otherPlayerBets[bet.username + ":" + bet.selection] = {
                    username: bet.username,
                    bet: bet.bet,
                    selection: bet.selection
                }
            } else {
                otherPlayerBets[bet.username + ":" + bet.selection].bet += bet.bet;
            }
        }
        totalBets += bet.bet;
    });

    return (
        <StyledContainer $compact={compact}>
            <BetListHeader><div>Total live bets</div><div>{GetCurrencySymbol() + ((totalBets/100).toFixed(2))}</div></BetListHeader>
            <hr style={{width: "100%", border: "1px solid rgb(71 71 71)"}}/>
            <BetTable>
            { Object.keys(playerBets).length > 0 &&
                <PlayerBetContainer> { 
                        Object.keys(playerBets).map( (key, index) => (
                            <BetLine key={`${key}:${playerBets[key].selection}:${playerBets[key].bet}`}>
                                { index === 0 ? <PlayerName><PlayerIcon type="avatar" size="sm" />{ "Your bet" }</PlayerName> : <PlayerName></PlayerName> }
                                <PlayerBet>
                                    <div style={{display: "flex", justifyContent: "center"}}><img style={{height: "12px", marginRight: "10px"}} src={shieldForSelection(playerBets[key].selection)}/>{multiplierForSelection(playerBets[key].selection)}</div>
                                    <div style={{display: "flex"}}>
                                        { GetCurrencySymbol() + ((playerBets[key].bet/100).toFixed(2)) }
                                    </div>
                                </PlayerBet>
                            </BetLine>
                        ))
                } </PlayerBetContainer>
                }
                {
                    Object.keys(otherPlayerBets).map( (key, index) => (
                        <BetLine key={`${key}:${otherPlayerBets[key].selection}:${otherPlayerBets[key].bet}`}>
                            <PlayerName><PlayerIcon type="avatar" size="sm" />{ otherPlayerBets[key].username }</PlayerName>
                            <PlayerBet>
                                <div style={{display: "flex", justifyContent: "center"}}><img style={{height: "12px", marginRight: "10px"}} src={shieldForSelection(otherPlayerBets[key].selection)}/>{multiplierForSelection(otherPlayerBets[key].selection)}</div>
                                <div style={{display: "flex"}}>
                                    { GetCurrencySymbol() + ((otherPlayerBets[key].bet/100).toFixed(2)) }
                                </div>
                            </PlayerBet>
                        </BetLine>
                    ))
                }
            </BetTable>
        </StyledContainer>
    )
};

interface BetButtonsProps {
    bets: INamedBet[];
    playerName?: string;
    winPhase: boolean;
    winningColour: RouletteColour;
    compact: boolean;
}

const multiplierForSelection = (selection: string) => {
    switch(selection) {
        case "RED":
            return "Bear x2";
        case "GREEN":
            return "Buffalo x24";
        case "BLACK":
            return "Wolf x2";
    }
}


const shieldForSelection = (selection: string) => {
    switch(selection) {
        case "RED":
            return RedShieldImage;
        case "GREEN":
            return GreenShieldImage;
        case "BLACK":
            return BlackShieldImage;
    }
}

export default BetsList;