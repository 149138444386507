import { styled } from "styled-components";

import BlackWolfButton from "../../assets/UI/black-wolf-button-bg.png";
import RedBearButton from "../../assets/UI/red-bear-button-bg.png";
import GreenBuffaloButton from "../../assets/UI/green-buffalo-button-bg.png";
import BetButton from "../BetButton/BetButton";
import { CurrencyFormat, GetCurrencySymbol } from "@lindar-joy/goosicorn-quickies-library";



const StyledContainer = styled.div`
    display: flex;
    background-color: #22100a;
    flex-direction: row;
    gap: 8px;
    width: 646px;
    box-sizing: border-box;
    justify-content: space-evenly;
    align-self: center;
    z-index: 90;
    margin-bottom: -8px;
    
    border-radius: 10px;

    @media (max-width: 680px) {
        width: 93%;
    }
`;

export const BetButtonBackground = styled.div<{$image: string}>`
    aspect-ratio: 0.85;
    height: 118px;
    border-radius: 6px;
    background-image: url(${ props => props.$image });
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
`;


const MultiplierText = styled.div`
    font-size: 13px;
    font-variant-numeric: tabular-nums;
    margin-bottom: 7px;
`;

const BetButtons: React.FC<BetButtonsProps> = ({
    selectedColour,
    onColourSelect,
    bet,
    disabled,
    ...props
}) => {

    return (
        <StyledContainer>
            <div style={{position: "absolute", boxSizing: "border-box", textAlign: "center", paddingLeft: "10px", paddingRight: "10px", paddingTop: "2px", paddingBottom: "2px", borderRadius: "3px", color: "#d6d6d6", fontWeight: "bold", transform: "translateY(-20px)", backgroundColor: "#22100a57"}}>Place your bets</div>
            <BetButtonBackground $image={RedBearButton}>
                <MultiplierText>win {GetCurrencySymbol() + (((2 * bet) / 100).toFixed(0))}</MultiplierText>
                <BetButton disabled={ disabled } bet={bet} buttonColour="RED" onSelect={() => {onColourSelect("RED")}}/>
            </BetButtonBackground>
            <BetButtonBackground $image={GreenBuffaloButton}>
                <MultiplierText>win {GetCurrencySymbol() + (((24 * bet) / 100).toFixed(0))}</MultiplierText>
                <BetButton disabled={ disabled } bet={bet} buttonColour="GREEN" onSelect={() => {onColourSelect("GREEN")}}/>
            </BetButtonBackground>
            <BetButtonBackground $image={BlackWolfButton}>
                <MultiplierText>win {GetCurrencySymbol() + (((2 * bet) / 100).toFixed(0))}</MultiplierText>
                <BetButton disabled={ disabled } bet={bet} buttonColour="BLACK" onSelect={() => {onColourSelect("BLACK")}}/>
            </BetButtonBackground>
        </StyledContainer>
    )
};

interface BetButtonsProps {
    bet: number;
    selectedColour: RouletteColour;
    disabled: boolean;
    onColourSelect: (colour: RouletteColour) => void;
}

export type RouletteColour = "NONE" | "RED" | "BLACK" | "GREEN";

export default BetButtons;