import { styled } from "styled-components";
import { CurrencyFormat, GetCurrencySymbol } from "@lindar-joy/goosicorn-quickies-library";
import WinBGImage from "../../assets/UI/win-container.png";

const StyledContainer = styled.div<{$show: boolean}>`
    padding: 10px;
    display: flex;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    aspect-ratio: 3 / 1;
    flex-direction: column;
    background-image: url(${WinBGImage});
    justify-content: center;
    align-items: center;
    transition: transform 330ms ${props => props.$show ? "ease-out" : "ease-in"};
    transform: translateY(${props => props.$show ? 0 : -1024}px);
`;

const WinAmount = styled.p`
    font-size: 65px;
    background: linear-gradient(#FDF53E, #FEEF39, #E4A022, #BE5F06);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
    font-variant-numeric: tabular-nums;
    letter-spacing: 4;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #000000;
    font-weight: bold;
    font-family: Geneva, Verdana, sans-serif;
`;

const WinNotification: React.FC<WinNotificationProps> = ({
    show,
    winAmount,
    ...props
}) => {

    return (
        <StyledContainer $show={show}>
            <WinAmount>{GetCurrencySymbol() + (winAmount/100).toFixed(2)}</WinAmount>
        </StyledContainer>
    )
};

interface WinNotificationProps {
    show: boolean;
    winAmount: number;
}

export default WinNotification;