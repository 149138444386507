import { styled } from "styled-components";


const StyledContainer = styled.div`
    padding-left: 10px;
    padding-right: 10px;
`;

const BarBG = styled.div`
    background: rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    height: 11px;
    width: 100%;
    padding: 2px;
`;

const BarFill = styled.div`
    background: rgba(256, 256, 256, 0.6);
    border-radius: 12px;
    height: 7px;
    
    margin-top: 2px;
    margin-left: 2px;
    box-sizing: border-box;
    transition: width 60ms;
`;

const Timer: React.FC<TimerProps> = ({
    time,
    ...props
}) => {

    return (
        <StyledContainer>
            <BarBG><BarFill id="roulette-timer-fill"/></BarBG>
        </StyledContainer>
    )
};

interface TimerProps {
    time: number
}

export default Timer;