const packageJSON = require("../package.json");

import { useEffect, useState } from 'react';

import "@lindar-joy/goosicorn-quickies-library/lib/css/style.css";
import './App.css';

import { CurrencyFormat, FadeAudio, Game, LoadAudio, PlayAudio, RigNumbers, Timeout, emitGameAction, useBfrStatusListener, useCommonBalanceListener, useCommonConnectionListener, useGameEventListener } from "@lindar-joy/goosicorn-quickies-library";

import { IConfigEvent, IShowEvent } from './events/showEvent';
import {
    BackgroundContainer, ColumnContainer, ControlsContainer, Logo, StyledContainer,
    StyledGameContainer
} from './styles';

import { GameHistoryRenderer } from './GameHistoryRenderer';
import BetButtons, { RouletteColour } from './components/BetButtons/BetButtons';
import { GameRules } from './gameRules';
import BetsList from './components/BetsList/BetsList';
import PreviousResults from './components/PreviousResults/PreviousResults';
import RouletteStrip, { Phase, useRouletteStopListener } from './components/RouletteStrip/RouletteStrip';
import { Stomp } from '@stomp/stompjs';
import { IBetEvent } from './events/betEvent';
import Timer from './components/Timer/Timer';
import WinNotification from './components/WinNotification/WinNotification';
import HUD from './components/HUD/HUD';

interface IResult {
    result: number;
    colorResult: string;
    engineRoundId: string;
    engineRoundTime: number;
}

export type ENGINE_PHASE = "PLACE_BET" | "NO_MORE_BETS" | "RESULT"

interface IGamePlayEvent {
    
    gameId: string;
    engineRoundId: string;
    engineRoundTime: number;
    enginePhase: ENGINE_PHASE;
    result: IResult;
    resultCollection: string[];
    lastResults: IResult[];
}

export interface IBet {
    selection: RouletteColour;
    bet: number;
    potentialWin: number;
    spinId: string;
}

export interface INamedBet extends IBet {
    username: string;
}

export interface IPublicBetEvent {
    goosicornUsername: string;
    gameAction: string;
}

let betsBacking: INamedBet[] = [];
let lastEnginePhase: ENGINE_PHASE;
let previousResultsBacking: IResult[] = [];
let winningColourBacking: RouletteColour;
let usernameBacking: string;
let sessionWinningsBacking = 0;
let winBacking = 0;

function App() {

    let [enginePhase, setEnginePhase] = useState<string>();
    let [loading, setLoading] = useState<boolean>(true);
    let [showEvent, setShowEvent] = useState<IShowEvent>();
    let [playing, setPlaying] = useState<boolean>(false);
    let [betAmount, setBetAmount] = useState<number>(0);
    let [stake, setStake] = useState<number>(0);
    let [freePlay, setFreePlay] = useState<boolean>(false);
    let [betIndex, setBetIndex] = useState<number>(0);
    let [bets, setBets] = useState<number[]>([ 50, 100, 200, 500, 1000 ]);
    let [playerBets, setPlayerBets] = useState<INamedBet[]>([]);
    let [sessionWinnings, setSessionWinnings] = useState<number>(0);
    let [ spinInProgress, setSpinInProgress ]= useState<boolean>(false);
    let [sessionLosses, setSessionLosses] = useState<number>(0);
    let [balance, setBalance] = useState<number>(0);
    let [bfrInUse, setBfrInUse] = useState<boolean>(false);
    let [bfrCount, setBfrCount] = useState<number>(0);
    let [showGame, setShowGame] = useState<boolean>(false);
    let [ previousResults, setPreviousResults ] = useState<IResult[]>([]);
    let [ username, setUsername ] = useState<string>("");
    let [stripPhase, setStripPhase] = useState<Phase>("STOPPED");
    let [target, setTarget] = useState<number>(0);
    let [ selectedColour, setSelectedColour ] = useState<RouletteColour>("NONE");
    let [ winningColour, setWinningColour ] = useState<RouletteColour>("NONE");
    let [ engineRoundId, setEngineRoundId ] = useState<string>();
    let [ betTime, setBetTime ] = useState<number>(0);
    let [ winAmount, setWinAmount ] = useState<number>(0);
    let [ noMoreBets, setNoMoreBets ] = useState<boolean>(false);

    const connectStomp = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const connectorUrl = urlParams.get("server");
        const gameId = urlParams.get("gameId");

        if (!connectorUrl) {
            throw new Error(`Missing connector URL`);
        }
        const connectorParts = connectorUrl.split(":");
        let broadcasterUrl = "";
        if (connectorParts[0] === "https") {
            broadcasterUrl = "wss://" + connectorParts[1].split("/")[2] + "/multigame";
        } else {
            broadcasterUrl = "ws://" + connectorParts[1] + ":9001/multigame";
        }

        const stompClient = Stomp.over(() => new WebSocket(broadcasterUrl));
        stompClient.reconnect_delay = 5000;
        //We do not want the stomp client showing all the events in the console
        stompClient.debug = (msg) => {}

        stompClient.onStompError = ( (error) => {
            console.log("Socket closed by server")
            throw new Error(error.body);
        })

        stompClient.connect({}, () => {
            // setBroadcasterConnection(true);
            stompClient.subscribe(`/topic/${gameId}`, (frame) => {
                const event = JSON.parse(frame.body);
                
                const gamePlay = event.gamePlay as IGamePlayEvent;
                if (event.type === "multi-roulette:update-gameplay") {                    
                    if (gamePlay.result?.result) {
                        console.log(gamePlay.enginePhase, gamePlay.result.result);
                    }

                    if (event.rouletteTick) {
                        const timerFill = document.getElementById("roulette-timer-fill");
                        if (timerFill) {
                            timerFill.style.width = `calc(${(event.rouletteTick / 150) * 100}% - 4px)`;
                            if (event.enginePhase === "PLACE_BET" && event.rouletteTick > 135) {
                                if (!noMoreBets) {
                                    setNoMoreBets(true);
                                }
                                
                            }
                            
                        }
                        
                        // setBetTime(event.rouletteTick);
                    }
                    setEnginePhase(gamePlay.enginePhase);
                    switch(gamePlay.enginePhase) {
                        case "PLACE_BET":
                            if (lastEnginePhase !== gamePlay.enginePhase) {
                                betsBacking = [];
                                setPlayerBets(betsBacking);
                                PlayAudio("Main", true, 0.25);
                                FadeAudio("Free Spins", 0.25, 0, 330);
                            }
                        break;
                        case "RESULT":
                            if (lastEnginePhase !== gamePlay.enginePhase)  {
                                const timerFill = document.getElementById("roulette-timer-fill");
                                if (timerFill) {
                                    timerFill.style.width = "0px";
                                }
                                setNoMoreBets(false);
                                console.log(`RESULT:`);
                                console.log(gamePlay);
                                setTarget(gamePlay.result.result);
                                winningColourBacking = gamePlay.result.colorResult as RouletteColour;
                                setWinningColour("NONE");
                                previousResultsBacking = gamePlay.lastResults;
                                setStripPhase("STOPPING");
                            }
                        break;
                        case "NO_MORE_BETS":
                            if (lastEnginePhase !== gamePlay.enginePhase)  {
                                PlayAudio("Free Spins", true, 0.5);
                                FadeAudio("Main", 0.5, 0, 330);
                                setSpinInProgress(true);
                                setStripPhase("STARTING");
                                setSelectedColour("NONE");    
                                winBacking = 0;
                                setWinAmount(0);
                            }
                            
                        break;
                        default:
                            console.log(gamePlay.enginePhase);
                    }
                    lastEnginePhase = gamePlay.enginePhase;
                }
                
                if (event.type === "multi-roulette:available-actions" && event.gamePhase === "PLACE_BET") {
                    setEngineRoundId(event.engineRoundId);
                    console.log(`Engine Round Id: ${event.engineRoundId}`);
                }

                if (event.type === "common:public-bet") {
                    const publicBetEvent = event as IPublicBetEvent;
                    const betEvent = JSON.parse(publicBetEvent.gameAction) as IBet;
                    betsBacking = betsBacking.slice(0);
                    betsBacking.push({
                        username: publicBetEvent.goosicornUsername,
                        bet: betEvent.bet,
                        potentialWin: betEvent.potentialWin,
                        selection: betEvent.selection,
                        spinId: betEvent.spinId
                    });
    
                    setPlayerBets(betsBacking);
                }

                if (event.type === "common:public-win") {
                    if (event.goosicornUsername === usernameBacking) {
                        winBacking += event.amount;
                        sessionWinningsBacking += event.amount;
                        setWinAmount(winBacking);
                        setSessionWinnings(sessionWinningsBacking);
                    }
                }
            })
        }, (error) => {
            console.error(error);
        })
    };

    useRouletteStopListener(async () => {
        console.log("Roulette Wheel Stop");
        await Timeout(330);
        setSessionLosses(sessionLosses + stake);
        setWinningColour(winningColourBacking);
        if (previousResultsBacking.length > 0) {
            setPreviousResults(previousResultsBacking);
        }
        setStake(0);
        setSpinInProgress(false);
    });

    useEffect(() => {
        (async () => {
            connectStomp();
            await LoadAudio([
                "music", "sfx"
            ]);
            setLoading(false);
            
            (async () => {
                setTimeout(() => setLoading(false), 1000);
                setTimeout(() => setShowGame(true), 3000);
            })();
            
        })();
    }, []);

    useCommonConnectionListener( connectionData => {
        setUsername(connectionData.goosicornUsername);
        usernameBacking = connectionData.goosicornUsername;
    });

    useCommonBalanceListener(balanceEvent => {
        setBalance(balanceEvent.amount);
    });

    useBfrStatusListener( status => {
        setBfrInUse(status.inUse);
        setBfrCount(status.bfrCount);
    });

    useGameEventListener((event) => {
        switch (event.type) {
            case "multi-roulette:config":
                const configEvent = event as IConfigEvent;
                break;
            case "multi-roulette:show":
                const showEvent = event as IShowEvent;
                setEnginePhase(showEvent.currentState);
                setEngineRoundId(showEvent.engineRoundId);
                const betIndex = bets.indexOf(showEvent.defaultBet);
                setBetIndex(betIndex > -1 ? betIndex : 0);
                setBetAmount(bets[betIndex]);
                setPreviousResults(showEvent.lastResults);
                setShowEvent(showEvent);
                console.log(showEvent.currentState);
                
                if (showEvent.currentState === "NO_MORE_BETS") {
                    setStripPhase("STARTING");
                }

                if (showEvent.currentState === "RESULT") {
                    setTarget(showEvent.result.result);
                    setStripPhase("STOPPING");
                    
                }
                break;
            case "multi-roulette:bet":
                
                break;
        }
    });

    const playText = () => {
        if (enginePhase !== "PLACE_BET" || spinInProgress) {
            return "NO MORE BETS";
        }
        if (selectedColour === "NONE") {
            return "SELECT A COLOUR";
        } else {
            return "PLACE BET"
        }
    }

    const placeBet = (colour: RouletteColour) => {
        if (enginePhase === "PLACE_BET") {
            // setFreePlay(usingBFRBalance);
        
            emitGameAction({
                type: 'multi-roulette:bet',
                body: {
                    engineRoundId: engineRoundId,
                    selection: colour,
                    bet: betAmount
                }
            });
            setStake( stake + betAmount );
            setSelectedColour("NONE");
        }
    }

    return (
        <div>
            {!loading &&
                <div>
                    <BackgroundContainer />
                    <Game
                        gameVersion={packageJSON.version}
                        transparentGameArea={true}
                        balance={balance}
                        gameName="Buffalo Bills Roulette"
                        bets={bets}
                        bet={betAmount || 0}
                        gameHistoryDetailView={GameHistoryRenderer}
                        gameAreaAutoScale={false}
                        winnings={sessionWinnings - sessionLosses}
                        gameRulesContent={GameRules( username )}
                        disablePlay={ selectedColour === "NONE" || enginePhase !== "PLACE_BET" || spinInProgress }
                        gameAreaWidth={"100%"}
                        showAdditionalGameRules={false}
                        gameAreaHeight={"calc(100%)"}
                        playText={playText()}
                        supportedOrientations={["DESKTOP_LANDSCAPE", "DESKTOP_PORTRAIT", "MOBILE_PORTRAIT"]}
                        showPlayIcon={false}
                        noHUD={true}
                        onBetSelect={bet => setBetAmount(bet)}
                        onPlay={ async (usingBFRBalance) => {
                            if (enginePhase === "PLACE_BET") {
                                setFreePlay(usingBFRBalance);
                            
                                emitGameAction({
                                    type: 'multi-roulette:bet',
                                    body: {
                                        engineRoundId: engineRoundId,
                                        selection: selectedColour,
                                        bet: betAmount
                                    }
                                });
                                setStake( stake + betAmount );
                                setSelectedColour("NONE");
                            }
                            
                        }}
                    >
                        <StyledGameContainer>
                            <Logo />
                            <RouletteStrip onRestitute={() => setStripPhase("STOPPED")} onStarted={() => setStripPhase("SPINNING")} phase={stripPhase} target={target}></RouletteStrip>
                            <BetsList compact={enginePhase === "PLACE_BET"} winPhase={enginePhase === "RESULT" && !spinInProgress} winningColour={winningColour} bets={ playerBets } playerName={ username }></BetsList>    
                            <PreviousResults previousResults={ previousResults }/>    
                            { (enginePhase === "PLACE_BET" && !spinInProgress) && <Timer time={betTime} /> }
                            { (enginePhase === "PLACE_BET" && !spinInProgress) && <BetButtons disabled={noMoreBets} selectedColour={ selectedColour } onColourSelect={ colour => { 
                                setSelectedColour(colour);
                                placeBet(colour);
                                } } bet={betAmount}/>
                            }
                            
                            <HUD
                                totalWin={winAmount}
                                totalBet={stake}
                                betChangeDisabled={enginePhase !== "PLACE_BET"}
                                balance={balance}
                                betAmount={betAmount}
                                onBetDecrease={ () => {
                                    let bIndex = betIndex - 1;
                                    
                                    bIndex = Math.max(0, bIndex);
                                    setBetIndex(bIndex);
                                    setBetAmount(bets[bIndex]);
                                } }
                                onBetIncrease={ () => {
                                    let bIndex = betIndex + 1;                                    
                                    bIndex = Math.min(bets.length - 1, bIndex);
                                    setBetIndex(bIndex);
                                    setBetAmount(bets[bIndex]);
                                } }
                            />
                        </StyledGameContainer>
                        <WinNotification show={enginePhase === "CELEBRATION" && winAmount > 0} winAmount={winAmount} />
                    </Game>
                    
                </div>
            }

            {/* { !showGame && <Splash />} */}
        </div>


    );
}

export default App;
